<template>
  <div class="search-area-simple">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="wrapper">
        <div class="area search">
          <div
            class="form"
            :class="{ adjacent: searchForm.adjacentFlg }"
            v-for="searchForm in searchForms"
            :key="searchForm.id"
          >
            <search-area-simple-form
              :form="searchForm"
              :data-test="searchForm.id"
              @input="input"
              @clear="clear"
            />
          </div>
        </div>
        <div class="area buttons">
          <div v-if="toggleButtonFlg" class="button detail">
            <base-button-small-white data-test="detail" @click="toggle"
              >詳細検索</base-button-small-white
            >
          </div>
          <div v-if="searchButtonFlg" class="button search">
            <base-button-small-orange
              :disabled="waitFlg || invalid"
              data-test="search"
              @click="search"
              >{{ searchButtonLabel }}</base-button-small-orange
            >
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import SearchAreaSimpleForm from '@/components/parts/molecules/SearchAreaSimpleForm'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'SearchAreaSimple',

  components: {
    SearchAreaSimpleForm,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    ValidationObserver
  },

  props: {
    searchForms: { type: Array, default: () => [] },
    toggleButtonFlg: { type: Boolean, default: false },
    searchButtonFlg: { type: Boolean, default: false },
    searchButtonLabel: { type: String, default: '検索' },
    waitFlg: { type: Boolean, default: false }
  },

  methods: {
    input(inputObj) {
      this.$emit('input', inputObj)
    },
    clear(clearObj) {
      this.$emit('clear', clearObj)
    },
    toggle() {
      this.$emit('toggle')
    },
    search() {
      this.$emit('search')
    }
  }
}
</script>

<style lang="scss" scoped>
.search-area-simple {
  width: 100%;
  height: 73px;
  border: solid 1px #{$light-grey};
  background-color: #{$white_f7};
  box-sizing: border-box;
  font-size: 13px;
  .wrapper {
    height: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .search {
      display: flex;
      align-items: center;
      > .form ~ .form {
        margin-left: 20px;
        &.adjacent {
          margin-left: 5px;
        }
      }
    }
    > .buttons {
      display: flex;
      align-items: center;
      > .button ~ .button {
        margin-left: 10px;
      }
    }
  }
}
</style>

<template>
  <div class="vaccination-set-list">
    <dynamic-scroller
      class="table-body"
      id="table-body"
      ref="scroller"
      :items="vaccinationSets"
      :keyField="'patientId'"
      :min-item-size="152"
      :buffer="10"
    >
      <template v-slot="{ item, index, active }">
        <dynamic-scroller-item
          :item="item"
          :active="active"
          :data-index="index"
          :size-dependencies="[item.vaccinations]"
        >
          <div class="row" :class="{ first: index === 0 }">
            <div v-if="bulkMode" class="checkbox">
              <base-check-box
                v-if="findCheck(item)"
                :isChecked="findCheck(item).checkFlg"
                :disabled="findCheck(item).disabled"
                @input="check(item, $event)"
              />
            </div>
            <div class="card">
              <div class="information">
                <div class="owner-patient">
                  <div class="owner">
                    <div class="owner-show-id">
                      <div class="label">飼主ID</div>
                      <div class="value">{{ item.owner.showId }}</div>
                    </div>
                    <div class="owner-name" :title="toOwnerName(item.owner)">
                      <div class="label">飼主名</div>
                      <div class="value name bold">
                        {{ toOwnerName(item.owner) }}
                      </div>
                    </div>
                  </div>
                  <div class="patient">
                    <div class="patient-show-id">
                      <div class="label">患者ID</div>
                      <div class="value">{{ item.patient.showId }}</div>
                    </div>
                    <div
                      class="patient-name"
                      :title="patientTitle(item.patient)"
                    >
                      <div class="label">患者名</div>
                      <div class="value name">
                        <span class="bold">{{ item.patient.name }}</span>
                        <span>{{ toSpeciesBreed(item.patient) }}</span>
                        <span>{{ toAgeBirthday(item.patient) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="dates">
                  <div class="previous">
                    <div class="label">前回来院</div>
                    <div class="value">
                      {{ toDate(item.previousComingDate) || 'なし' }}
                    </div>
                  </div>
                  <div class="next">
                    <div class="label">次回予約</div>
                    <div class="value">
                      {{ toDate(item.nextReservationDate) || 'なし' }}
                    </div>
                  </div>
                </div>
                <div class="buttons">
                  <base-button-border-orange
                    class="button"
                    :styles="{ width: '100px' }"
                    @click="clickPatient(item)"
                    >患者詳細</base-button-border-orange
                  >
                  <base-button-border-orange
                    class="button"
                    :styles="{ width: '120px' }"
                    @click="clickAntibody(item)"
                    >予防接種履歴</base-button-border-orange
                  >
                  <base-button-border-orange
                    class="button"
                    :styles="{ width: '100px' }"
                    @click="clickReminder(item)"
                    >連絡履歴</base-button-border-orange
                  >
                </div>
              </div>
              <div class="vaccinations">
                <div class="table">
                  <div class="table-header">
                    <div class="cell vaccine-name">ワクチン</div>
                    <div class="cell previous-period">前回の接種/抗体検査</div>
                    <div class="cell ">現在の接種期間</div>
                    <div class="cell next-period">次回の接種期間</div>
                    <div class="cell previous-reminder">前回の連絡</div>
                  </div>
                  <div class="table-body">
                    <div
                      class="table-body-row"
                      :class="{ unmatched: !vaccination.matchFlg }"
                      v-for="vaccination in item.vaccinations"
                      :key="`${vaccination.patientVaccineId}`"
                    >
                      <div class="cell vaccine-name">
                        <div class="text">
                          {{ toVaccineName(vaccination.vaccineId) }}
                        </div>
                      </div>
                      <div class="cell previous-date-antibody-type">
                        <div class="text">
                          {{ toDateType(vaccination.previousDateAntibodyType) }}
                        </div>
                      </div>
                      <div
                        class="cell current-period"
                        :class="{
                          incomplete:
                            vaccination.matchFlg &&
                            vaccination.currentPeriod &&
                            !vaccination.completeFlg
                        }"
                      >
                        <div class="text">
                          {{ toPeriod(vaccination.currentPeriod, '期間外') }}
                        </div>
                      </div>
                      <div class="cell next-period">
                        <div class="text">
                          {{ toPeriod(vaccination.nextPeriod, '未設定') }}
                        </div>
                      </div>
                      <div class="cell previous-reminder">
                        <div class="text">
                          {{ toDateMedia(vaccination.previousVaccineReminder) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
  </div>
</template>

<script>
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import { mapGetters } from 'vuex'
import { makeBirthday, calcAge } from '@/utils/patient_info'
import { antibodyTypesEnToJp, mediaEnToJp } from '@/utils/vaccine_utils'
import moment from 'moment'

export default {
  name: 'VaccinationSetList',

  components: {
    BaseCheckBox,
    BaseButtonBorderOrange,
    DynamicScroller,
    DynamicScrollerItem
  },

  props: {
    vaccinationSets: { type: Array },
    checks: { type: Array },
    mailFlg: { type: Boolean, default: false },
    printFlg: { type: Boolean, default: false },
    csvFlg: { type: Boolean, default: false }
  },

  computed: {
    ...mapGetters({
      masterDatumById: 'master/getDataById',
      speciesById: 'species/getDataById'
    }),
    bulkMode() {
      return this.mailFlg || this.printFlg || this.csvFlg
    }
  },

  mounted() {
    const self = this
    const tableBody = document.getElementById('table-body')
    tableBody.onscroll = () => {
      const element = tableBody
      const clientHeight = element.clientHeight
      const scrollHeight = element.scrollHeight
      const scrollPosition = scrollHeight - (clientHeight + element.scrollTop)
      if (scrollPosition < 1) {
        self.$emit('scroll-bottom')
      }
    }
  },

  methods: {
    toOwnerName(owner) {
      return owner.lastName + ' ' + owner.firstName
    },
    toSpeciesBreed(patient) {
      const species = this.speciesById(patient.speciesId)
      const speciesName = species?.name || ''
      const slash = speciesName && patient.breed ? '/' : ''
      return `（${speciesName}${slash}${patient.breed}）`
    },
    toAgeBirthday(patient) {
      const age = calcAge(patient)
      const birthday = makeBirthday(patient)
      const slash = age && birthday ? '/' : ''
      return `${age}${slash}${birthday}`
    },
    patientTitle(patient) {
      const name = patient.name
      const speciesBreed = this.toSpeciesBreed(patient)
      const ageBirthday = this.toAgeBirthday(patient)
      return `${name} ${speciesBreed} ${ageBirthday}`
    },
    toVaccineName(vaccineId) {
      const vaccine = this.masterDatumById('vaccines', vaccineId)
      return vaccine.name
    },
    toDate(date) {
      return date ? moment(date, 'YYYYMMDD').format('YYYY/MM/DD') : ''
    },
    toDateType(dateAntibodyType) {
      if (!dateAntibodyType) return 'なし'
      const date = this.toDate(dateAntibodyType.date)
      const jpType = antibodyTypesEnToJp[dateAntibodyType.type]
      return `${date} ${jpType}`
    },
    toPeriod(period, text) {
      if (!period) return text || ''
      const start = this.toDate(period.startDate)
      const end = this.toDate(period.endDate)
      return `${start} ～ ${end}`
    },
    toDateMedia(vaccineReminder) {
      if (!vaccineReminder) return 'なし'
      const date = this.toDate(vaccineReminder.date)
      const jpMedia = mediaEnToJp[vaccineReminder.media]
      return `${date} ${jpMedia}`
    },
    findCheck(vaccination) {
      return this.checks.find(
        check => check.patientId === vaccination.patientId
      )
    },
    check(vaccination, flg) {
      const patientId = vaccination.patientId
      this.$emit('check', patientId, flg)
    },
    clickPatient(vaccination) {
      const ownerId = vaccination.owner.id
      const patientId = vaccination.patientId
      this.$emit('click-patient', ownerId, patientId)
    },
    clickAntibody(vaccination) {
      const patientId = vaccination.patientId
      this.$emit('click-antibody', patientId)
    },
    clickReminder(vaccination) {
      const patientId = vaccination.patientId
      this.$emit('click-reminder', patientId)
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #{$light-grey};
.vaccination-set-list {
  height: 100%;
  > .table-body {
    height: 100%;
    .row {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-top: 20px;
      &.first {
        margin-top: 0;
      }
      > .checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
      }
      > .card {
        flex: 1;
        padding: 15px;
        background: #{$very-light-shade-brown};
        border: solid 1px #{$light-grey};
        > .information {
          display: flex;
          > .owner-patient {
            flex: 1;
            padding-right: 20px;
            > .owner {
              box-sizing: border-box;
              display: flex;
              align-items: center;
              line-height: 24px;
              font-size: 14px;
              > .owner-show-id {
                display: flex;
                align-items: center;
                height: 100%;
                > .label {
                  margin-right: 5px;
                  min-width: 42px;
                }
                > .value {
                  flex: 1;
                }
              }
              > .owner-name {
                flex: 1;
                display: flex;
                align-items: center;
                padding-left: 15px;
                > .label {
                  margin-right: 5px;
                  min-width: 42px;
                }
                > .value {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  &.bold {
                    font-weight: bold;
                  }
                  &.name {
                    flex: 1;
                    width: 0;
                    max-width: fit-content;
                  }
                }
              }
            }
            > .patient {
              box-sizing: border-box;
              display: flex;
              align-items: center;
              line-height: 24px;
              font-size: 14px;
              > .patient-show-id {
                display: flex;
                align-items: center;
                max-width: 200px;
                height: 100%;
                > .label {
                  margin-right: 5px;
                  min-width: 42px;
                }
                > .value {
                  flex: 1;
                }
              }
              > .patient-name {
                flex: 1;
                display: flex;
                align-items: center;
                padding-left: 15px;
                > .label {
                  margin-right: 5px;
                  min-width: 42px;
                }
                > .value {
                  flex: 1;
                  width: 0;
                  max-width: fit-content;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  > .bold {
                    font-weight: bold;
                  }
                }
              }
            }
          }
          > .dates {
            margin-right: 10px;
            > .previous,
            .next {
              box-sizing: border-box;
              display: flex;
              align-items: center;
              width: 145px;
              line-height: 24px;
              font-size: 14px;
              > .label {
                margin-right: 5px;
              }
              > .value {
                flex: 1;
              }
            }
          }
          > .buttons {
            display: flex;
            width: 350px;
            > .button ~ .button {
              margin-left: 10px;
            }
          }
        }
        > .vaccinations {
          margin-top: 10px;
          > .table {
            width: 100%;
            > .table-header {
              box-sizing: border-box;
              display: flex;
              justify-content: space-between;
              width: 100%;
              height: 30px;
              background-color: #{$gray_f0};
              border: solid $border-color;
              border-width: 1px 1px 0 1px;
              > .cell {
                box-sizing: border-box;
                width: 100%;
                line-height: 29px;
                padding: 0 8px;
                font-size: 13px;
                text-align: center;
                + .cell {
                  border-left: 1px solid $border-color;
                }
                &.vaccine-name {
                  min-width: 170px;
                }
                &.previous-date-antibody-type,
                &.current-period,
                &.next-period,
                &.previous-reminder {
                  min-width: 170px;
                }
              }
            }
            > .table-body {
              width: 100%;
              box-sizing: border-box;
              border: 1px solid $border-color;
              > .table-body-row {
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                width: 100%;
                min-height: 30px;
                border-top: 1px solid #{$light-grey};
                background-color: #{$white};
                &:first-child {
                  border-top: none;
                }
                &.unmatched {
                  background-color: #bdbdbd;
                  color: #{$brown-gray};
                }
                > .cell {
                  box-sizing: border-box;
                  width: 100%;
                  padding: 0 8px;
                  font-size: 13px;
                  text-align: center;
                  + .cell {
                    border-left: 1px solid $border-color;
                  }
                  &.vaccine-name {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 170px;
                  }
                  &.previous-date-antibody-type,
                  &.current-period,
                  &.next-period,
                  &.previous-reminder {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 170px;
                  }
                  &.incomplete {
                    color: #{$tomato};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

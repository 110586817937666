<template>
  <div class="search-area-simple-form">
    <div v-if="form.type === 'textBox'" class="text-box-form">
      <div v-if="form.label" class="label">{{ form.label }}</div>
      <div class="text-box">
        <validation-provider
          class="validate"
          :rules="{ notSurrogatePair: true }"
          v-slot="{ errors }"
        >
          <base-text-box
            data-test="text-box"
            :placeholder="form.placeholder"
            :styles="form.styles"
            :value="form.value"
            @input="input('value', $event)"
          />
          <div class="error">{{ errors[0] }}</div>
        </validation-provider>
      </div>
    </div>
    <div v-if="form.type === 'selectBox'" class="select-box-form">
      <div v-if="form.label" class="label">{{ form.label }}</div>
      <div class="select-box">
        <base-select-box
          data-test="select-box"
          :styles="form.styles"
          :selectData="form.selectData"
          :value="form.value"
          @input="input('value', $event)"
        />
      </div>
    </div>
    <div v-if="form.type === 'period'" class="period-form">
      <div v-if="form.label" class="label">{{ form.label }}</div>
      <div class="period">
        <validation-provider
          class="validate"
          :rules="`startDateRule:${form.value2}`"
          v-slot="{ errors }"
        >
          <base-date-picker
            data-test="period-start"
            :clearFlg="true"
            :minDate="form.minDate"
            :maxDate="form.maxDate"
            :value="form.value"
            @input="input('value', $event)"
            @clear="clear('value')"
          />
          <div class="error">{{ errors[0] }}</div>
        </validation-provider>
        <div class="wavy-line">～</div>
        <base-date-picker
          data-test="period-end"
          :clearFlg="true"
          :minDate="form.minDate2"
          :maxDate="form.maxDate2"
          :value="form.value2"
          @input="input('value2', $event)"
          @clear="clear('value2')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseDatePicker from '@/components/parts/atoms/BaseDatePicker'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'SearchAreaSimpleForm',

  components: {
    BaseTextBox,
    BaseSelectBox,
    BaseDatePicker,
    ValidationProvider
  },

  props: {
    form: { type: Object }
  },

  methods: {
    input(key, value) {
      const inputObj = { id: this.form.id, key, value }
      this.$emit('input', inputObj)
    },
    clear(key) {
      const clearObj = { id: this.form.id, key }
      this.$emit('clear', clearObj)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-area-simple-form {
  > .text-box-form {
    display: flex;
    align-items: center;
    > .label {
      margin-right: 5px;
    }
    > .text-box {
      @include validate-message();
    }
  }
  > .select-box-form {
    display: flex;
    align-items: center;
    > .label {
      margin-right: 5px;
    }
  }
  > .period-form {
    display: flex;
    align-items: center;
    > .label {
      margin-right: 5px;
    }
    > .period {
      display: flex;
      align-items: center;
      @include validate-message();
      > .wavy-line {
        margin: 0 10px;
      }
    }
  }
}
</style>

import { mmToPt, makePageSize } from '@/utils/print_utils'
import { createPdf } from 'pdfmake/build/pdfmake.min.js'
import '@/utils/vfs_fonts'

const makeOwnerAddress = (owner, patientsMap, prefectures, cellHeightInPt) => {
  const prefectureName =
    prefectures.find(v => v.id === owner.prefectureId)?.name || ''
  return {
    text:
      `〒${owner.postalCode.slice(0, 3)}-${owner.postalCode.slice(3)}` +
      '\n' +
      prefectureName +
      owner.address +
      '\n' +
      owner.building +
      '\n' +
      `${owner.lastName} ${owner.firstName}様` +
      '\n' +
      makePatientsText(patientsMap, owner.id),
    maxHeight: cellHeightInPt
  }
}

const makePatientsText = (patientsMap, ownerId) => {
  const patients = patientsMap.get(ownerId) ?? []
  return patients.map(v => v.showId + ': ' + v.name + '様').join('\n')
}

const makeRow = (owners, patientsMap, columns, prefectures, cellHeightInPt) => {
  const row = []
  for (let i = 0; i < columns; i++) {
    owners[i]
      ? row.push(
          makeOwnerAddress(owners[i], patientsMap, prefectures, cellHeightInPt)
        )
      : row.push([])
  }
  return row
}

export const makeDocDefinition = args => {
  if (args.printOptions.paperSize === 'PC') {
    return makePostCardDocDefinition(args)
  } else {
    return makeStarndardDocDefinition(args)
  }
}

const makeStarndardDocDefinition = ({
  printOptions,
  cellWidth,
  cellHeight,
  owners,
  patientsMap,
  prefectures
}) => {
  const { paperSize, rows, columns } = printOptions
  const pages = []
  let tableBody = []
  const cellsPerPage = rows * columns
  const cellWidthInPt = mmToPt(cellWidth)
  const cellHeightInPt = mmToPt(cellHeight)
  const widths = new Array(columns).fill(cellWidthInPt)
  for (let i = 0; i < owners.length; i += columns) {
    if (i > 0 && i % cellsPerPage === 0) {
      const page = {
        pageBreak: 'after',
        layout: 'sheetLayout',
        width: '*',
        table: { widths, heights: cellHeightInPt, body: tableBody }
      }
      pages.push(page)
      tableBody = []
    }
    const row = makeRow(
      owners.slice(i, i + columns),
      patientsMap,
      columns,
      prefectures,
      cellHeightInPt
    )
    tableBody.push(row)
  }
  if (
    owners.length === cellsPerPage ||
    owners.length % cellsPerPage !== 0 ||
    cellsPerPage === 1
  ) {
    const page = {
      layout: 'sheetLayout',
      width: '*',
      table: { widths, heights: cellHeightInPt, body: tableBody }
    }
    pages.push(page)
  }

  return {
    content: pages,
    styles: {},
    defaultStyle: {
      font: 'NotoSansMono',
      fontSize: 10,
      color: '#3e3e3e',
      preserveLeadingSpaces: true
    },
    pageSize: makePageSize(paperSize),
    pageMargins: [
      mmToPt(printOptions.leftRightMargins),
      mmToPt(printOptions.topBottomMargins)
    ],
    pageOrientation: printOptions.verticalFlg ? 'portrait' : 'landscape'
  }
}

const makePostCardDocDefinition = ({
  printOptions,
  cellWidth,
  owners,
  patientsMap,
  prefectures,
  clinic
}) => {
  const { paperSize } = printOptions
  const pages = []
  const cellWidthInPt = mmToPt(cellWidth)
  const makePage = (body, widths, pageBreakFlg = false) => {
    const basePage = {
      layout: 'sheetLayout',
      width: '*',
      table: {
        widths: widths,
        body: body
      }
    }
    const page = pageBreakFlg
      ? { ...basePage, pageBreak: 'before' }
      : { ...basePage }
    return page
  }

  for (let i in owners) {
    const textObjs = makePostCardTextObjs(
      owners[i],
      patientsMap,
      prefectures,
      clinic
    )
    const horizontalPositions = [
      mmToPt(printOptions.postCardOwnerAddressHorizontalPosition),
      mmToPt(printOptions.postCardOwnerNameHorizontalPosition),
      mmToPt(printOptions.postCardSenderHorizontalPosition)
    ]
    const verticalPositions = [
      mmToPt(printOptions.postCardOwnerAddressVerticalPosition),
      mmToPt(printOptions.postCardOwnerNameVerticalPosition),
      mmToPt(printOptions.postCardSenderVerticalPosition)
    ]
    const bodys = makePostCardTableBodys(
      textObjs,
      horizontalPositions,
      verticalPositions,
      printOptions.postCardSenderFlg
    )
    for (let j in bodys) {
      const body = bodys[j]
      // 長い文章を折り返す際、位置を調整しても機能する様なwidthを設定する
      const widths = [cellWidthInPt - horizontalPositions[j]]
      const pageBreakFlg = i !== '0' && j === '0'
      pages.push(makePage(body, widths, pageBreakFlg))
    }

    if (owners[i].postalCode) {
      pages.push(
        makePage(
          makeOwnerPostalCodeTableBody(
            owners[i].postalCode,
            printOptions.postCardOwnerPostalCodeHorizontalPosition,
            printOptions.postCardOwnerPostalCodeVerticalPosition
          ),
          new Array(7).fill(mmToPt(5.7))
        )
      )
    }
    if (printOptions.postCardSenderFlg && clinic.postalCode) {
      pages.push(
        makePage(
          makeClinicPostalCodeTableBody(
            clinic.postalCode,
            printOptions.postCardSenderPostalCodeHorizontalPosition,
            printOptions.postCardSenderPostalCodeVerticalPosition
          ),
          new Array(7).fill(mmToPt(5))
        )
      )
    }
  }

  return {
    content: pages,
    styles: {},
    defaultStyle: {
      font: 'NotoSansMono',
      fontSize: 10,
      color: '#3e3e3e',
      preserveLeadingSpaces: true
    },
    pageSize: makePageSize(paperSize),
    pageMargins: [
      mmToPt(printOptions.leftRightMargins),
      mmToPt(printOptions.topBottomMargins)
    ],
    pageOrientation: 'portrait'
  }
}

const makePostCardTableBodys = (
  textObjs,
  horizontalPositions,
  verticalPositions,
  postCardSenderFlg
) => {
  const ownerAddressTableBody = makePostCardTableBody(
    textObjs[0],
    horizontalPositions[0],
    verticalPositions[0],
    'left'
  )
  const ownerNameTableBody = makePostCardTableBody(
    textObjs[1],
    horizontalPositions[1],
    verticalPositions[1],
    'left'
  )
  if (postCardSenderFlg) {
    const hospitalNameTableBody = makePostCardTableBody(
      textObjs[2],
      horizontalPositions[2],
      verticalPositions[2],
      'right'
    )
    return [ownerAddressTableBody, ownerNameTableBody, hospitalNameTableBody]
  } else {
    return [ownerAddressTableBody, ownerNameTableBody]
  }
}

const makePostCardTableBody = (textObjs, x, y, alignment) => {
  const tableBody = []
  const baseObj = {
    relativePosition: { x, y },
    alignment
  }
  const row =
    textObjs.length === 1
      ? [{ ...baseObj, text: textObjs[0].text, fontSize: textObjs[0].fontSize }]
      : [{ ...baseObj, stack: textObjs }]
  tableBody.push(row)
  return tableBody
}

const makeOwnerPostalCodeTableBody = (postalCode, baseX, baseY) => {
  const xs = [0, 7, 14, 21.6, 28.4, 35.2, 42.0]
  const fontSize = 20
  return makePostalCodeTableBody(baseX, baseY, postalCode, xs, fontSize)
}

const makeClinicPostalCodeTableBody = (postalCode, baseX, baseY) => {
  const xs = [0, 4.4, 8.8, 13.2, 17.6, 22, 26.4]
  const fontSize = 12
  return makePostalCodeTableBody(baseX, baseY, postalCode, xs, fontSize)
}

const makePostalCodeTableBody = (baseX, baseY, postalCode, xs, fontSize) => {
  const tableBody = []
  for (let i in postalCode) {
    tableBody.push([
      {
        text: postalCode[i],
        relativePosition: { x: mmToPt(baseX + xs[i]), y: mmToPt(baseY) },
        fontSize: fontSize,
        alignment: 'center'
      }
    ])
  }
  return tableBody
}

const makePostCardTextObjs = (owner, patientsMap, prefectures, clinic) => {
  const ownerPrefectureName =
    prefectures.find(v => v.id === owner.prefectureId)?.name || ''
  const clinicPrefectureName =
    prefectures.find(v => v.id === clinic.prefectureId)?.name || ''
  return [
    [
      {
        text: ownerPrefectureName + owner.address + '\n' + owner.building,
        fontSize: 10
      }
    ],
    [
      {
        text: `${owner.lastName} ${owner.firstName}様`,
        fontSize: 24
      },
      {
        text: makePatientsText(patientsMap, owner.id),
        fontSize: 10
      }
    ],
    [
      {
        text:
          clinicPrefectureName +
          clinic.address +
          '\n' +
          clinic.building +
          '\n' +
          clinic.name,
        fontSize: 10
      }
    ]
  ]
}

// args = {
//   printOptions,
//   cellWidth,
//   cellHeight,
//   owners,
//   prefectures
// }
export const printAddressSheet = args => {
  const docDefinition = makeDocDefinition(args)
  const fonts = {
    NotoSansMono: {
      normal: 'NotoSansMonoCJKJPRegular.otf',
      bold: 'NotoSansMonoCJKJPRegular.otf',
      italics: 'NotoSansMonoCJKJPRegular.otf',
      bolditalics: 'NotoSansMonoCJKJPRegular.otf'
    }
  }
  const tableLayouts = {
    sheetLayout: {
      defaultBorder: false,
      hLineWidth: () => 0,
      vLineWidth: () => 0,
      paddingLeft: () => 0,
      paddingTop: () => 0,
      paddingRight: (i, node) => {
        return i === node.table.widths.length - 1
          ? 0
          : mmToPt(args.printOptions.horizontalMargins)
      },
      paddingBottom: (i, node) => {
        return i === node.table.body.length - 1
          ? 0
          : mmToPt(args.printOptions.verticalMargins)
      }
    }
  }
  createPdf(docDefinition, tableLayouts, fonts).open()
}

<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="vaccination-mail-popup"
      @keyup.esc.stop="confirmClose"
      tabindex="-1"
    >
      <base-loading :waitFlg="waitFlg" />
      <validation-observer
        class="validation-observer"
        ref="observer"
        v-slot="{ invalid }"
      >
        <popup
          :title="'メール送信フォーム'"
          :buttons="['閉じる', '送信']"
          :invalid="invalid || waitFlg || lookOnlyFlg === 1"
          @click-close-mark="confirmClose"
          @cancel="confirmClose"
          @decision="bulkCreateMail"
        >
          <template v-slot:content>
            <div class="mail-content">
              <div class="list">
                <div class="label">患者名（飼主名）</div>
                <div class="body">
                  <div
                    class="item"
                    v-for="vaccinationSet in reminderVaccinationSets"
                    :key="vaccinationSet.name"
                  >
                    {{ vaccinationSet.name }}
                  </div>
                </div>
              </div>
              <div class="title">
                <text-input-form
                  :requiredFlg="true"
                  :placeholder="'50文字まで'"
                  :maxlength="50"
                  :styles="{ width: '360px' }"
                  v-model="mail.title"
                  >件名</text-input-form
                >
              </div>
              <div class="message">
                <multiple-text-input-form
                  :requiredFlg="true"
                  v-model="mail.message"
                  >本文
                  <template #title-supplement
                    ><div :style="supplementStyles">
                      {{ supplement }}
                    </div></template
                  ></multiple-text-input-form
                >
              </div>
            </div>
          </template>
        </popup>
        <announce-popup
          v-if="popupFlg"
          v-bind="popup"
          @close="popup.close"
          @cancel="popup.cancel"
          @decision="popup.decision"
          >{{ popup.message }}</announce-popup
        >
      </validation-observer>
    </div>
  </focus-trap>
</template>

<script>
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import Popup from '@/components/popups/Popup'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import MultipleTextInputForm from '@/components/parts/molecules/MultipleTextInputForm'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'VaccinationMailPopup',

  components: {
    BaseLoading,
    Popup,
    TextInputForm,
    MultipleTextInputForm,
    AnnouncePopup,
    FocusTrap,
    ValidationObserver
  },

  mixins: [CheckPopupInputDifference],

  props: {
    reminderVaccinationSets: { type: Array }
  },

  data() {
    return {
      initialData: {},
      inputData: {},
      mail: {
        title: '',
        message: ''
      },
      supplementStyles: {
        marginTop: '5px',
        width: '230px',
        fontSize: '13px',
        whiteSpace: 'pre-line'
      },
      supplement:
        '（飼主名）（患者名）（ワクチン名/接種期間）の部分は飼主名、患者名、ワクチン名/接種期間に自動変換されます。\n（飼主名）（患者名）（ワクチン名/接種期間）の文字を変更すると変換されなくなりますのでご注意下さい。',
      popupFlg: false,
      popup: {
        layerNumber: 2,
        type: '',
        title: '',
        message: '',
        buttons: ['閉じる'],
        close: () => {},
        cancel: () => {},
        decision: () => {}
      },
      unsavedPopup: {
        layerNumber: 2,
        type: 'alert',
        title: '確認',
        message: '入力内容を保存せずに閉じようとしています。\nよろしいですか？',
        buttons: ['キャンセル', '閉じる'],
        close: () => {},
        cancel: () => (this.popupFlg = false),
        decision: () => this.$emit('close')
      },
      updatedFlg: false,
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      clinic: 'clinic/getData'
    }),
    diffFlg() {
      return !_.isEqual(this.initialData, this.inputData)
    }
  },

  created() {
    this.$nextTick(() => {
      this.$refs.observer.validate()
      this.$refs.observer.reset()
    })
    const header = `${this.clinic.name}より（飼主名）様へ`
    const footer =
      '==============================\n本メールは送信専用です。\n本メールに返信しないようお願いいたします。\n=============================='
    this.mail.message = `${header}\n\n（患者名）\n（ワクチン名/接種期間）\n\n${footer}`
    this.inputData = this.mail
    this.initialData = { ...this.inputData }
  },

  methods: {
    confirmClose() {
      if (this.diffFlg) {
        this.popup = this.unsavedPopup
        this.popupFlg = true
      } else {
        this.$emit('close')
      }
    },
    async bulkCreateMail() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'vaccineReminders/bulkCreateMail',
        {
          reminderVaccinationSets: this.reminderVaccinationSets,
          mail: this.mail
        }
      )
      if (res.result === true) {
        this.initialData = { ...this.inputData }
        this.updatedFlg = true
        if (res.errorPatientIds.length > 0) {
          const patients = this.reminderVaccinationSets.map(v => v.patient)
          const patientNames = res.errorPatientIds.map(patientId => {
            const patient = patients.find(v => v.id === patientId)
            return `・${patient.name}`
          })
          this.popup.type = 'alert'
          this.popup.title = '注意'
          this.popup.message = `メールを送信しました。\n但し、下記の患者のメール送信には失敗しました。\n${patientNames.join(
            '\n'
          )}\n飼主または患者が削除済み、もしくは飼主にメールアドレスが設定されていません。\nブラウザの更新をクリックし、最新の飼主患者情報をご確認下さい。`
        } else {
          this.popup.type = 'success'
          this.popup.title = '完了'
          this.popup.message = 'メールを送信しました'
        }
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.message = 'メールの送信に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.close = () => {
        this.closePopup()
      }
      this.popupFlg = true
      this.waitFlg = false
    },
    closePopup() {
      this.popupFlg = false
      if (this.updatedFlg) {
        this.$emit('close')
        this.$emit('updated')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vaccination-mail-popup {
  position: absolute;
  top: 0;
  left: 0;
  .mail-content {
    width: 650px;
    margin-bottom: 20px;
    > .list {
      width: 600px;
      max-height: 120px;
      display: flex;
      margin: 0 auto;
      font-size: 15px;
      > .label {
        width: 40%;
      }
      > .body {
        width: 60%;
        overflow: auto;
        > .item {
          white-space: nowrap;
        }
      }
    }
    > .template {
      width: 600px;
      margin: 0 auto;
    }
    > .title {
      width: 600px;
      margin: 30px auto 0;
    }
    > .message {
      width: 600px;
      margin: 30px auto 0;
    }
  }
}
</style>

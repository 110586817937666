<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="antibodies-popup"
      @keyup.esc.stop="$emit('close')"
      tabindex="-1"
    >
      <popup
        :buttons="['閉じる']"
        @close="$emit('close')"
        @click-close-mark="$emit('close')"
      >
        <template v-slot:content>
          <div class="content-wrap">
            <div class="header">
              <div class="title">予防接種履歴</div>
              <base-button-register :text="'新規登録'" @click="openNewPopup" />
            </div>
            <div class="patient-name">
              <div class="label">患者名</div>
              <div class="value">{{ patientName }}</div>
            </div>
            <div class="list">
              <div class="list-header">
                <div class="cell date">
                  <div class="text">日付</div>
                </div>
                <div class="cell results">
                  <div class="text">接種/抗体検査情報</div>
                </div>
              </div>
              <div class="list-body">
                <div
                  class="row"
                  v-for="item in listAntibodies"
                  :key="item.id"
                  @click="openEditPopup(item.id)"
                >
                  <div class="cell date">
                    <div class="text">{{ toJpDate(item.date) }}</div>
                  </div>
                  <div class="cell results" :title="item.results">
                    <div class="text">{{ item.results }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </popup>
      <antibody-popup
        v-if="antibodyPopupFlg"
        :patientName="patientName"
        :patientVaccines="patientVaccines"
        :antibody="editAntibody"
        :antibodyTypes="editAntibodyTypes"
        @close="closeAntibodyPopup"
        @updated="updated"
      />
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import AntibodyPopup from '@/components/popups/AntibodyPopup'
import { mapGetters } from 'vuex'
import { FocusTrap } from 'focus-trap-vue'
import { antibodyTypesEnToJp } from '@/utils/vaccine_utils'
import moment from 'moment'

export default {
  name: 'AntibodiesPopup',

  components: {
    FocusTrap,
    Popup,
    BaseButtonRegister,
    AntibodyPopup
  },

  props: {
    patientName: { type: String },
    patientVaccines: { type: Array },
    antibodies: { type: Array },
    antibodyTypes: { type: Array }
  },

  data() {
    return {
      antibodyPopupFlg: false,
      editAntibody: undefined,
      editAntibodyTypes: undefined
    }
  },

  computed: {
    ...mapGetters({
      masterDatumById: 'master/getDataById'
    }),
    listAntibodies() {
      const antibodies = this.antibodies
      return [...antibodies]
        .sort((a, b) => (a.date < b.date ? 1 : -1))
        .flatMap(antibody => {
          const antibodyTypes = this.antibodyTypes.filter(
            antibodyType => antibodyType.antibodyId === antibody.id
          )
          const results = antibodyTypes
            .flatMap(antibodyType => {
              if (antibodyType.delFlg || !antibodyType.type) return []
              const vaccine = this.masterDatumById(
                'vaccines',
                antibodyType.vaccineId
              )
              const jpType = antibodyTypesEnToJp[antibodyType.type]
              const result = `${vaccine.name}（${jpType}）`
              return result
            })
            .join('、')
          return { id: antibody.id, date: antibody.date, results }
        })
    }
  },

  methods: {
    toJpDate(date) {
      return date ? moment(date, 'YYYYMMDD').format('YYYY年MM月DD日') : ''
    },
    openNewPopup() {
      this.antibodyPopupFlg = true
    },
    openEditPopup(id) {
      this.editAntibody = this.antibodies.find(antibody => antibody.id === id)
      this.editAntibodyTypes = this.antibodyTypes.filter(
        antibodyType => antibodyType.antibodyId === id
      )
      this.antibodyPopupFlg = true
    },
    closeAntibodyPopup() {
      this.antibodyPopupFlg = false
      this.editAntibody = undefined
      this.editAntibodyTypes = undefined
    },
    updated() {
      this.$emit('updated')
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #{$very-light-grey};
.antibodies-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content-wrap {
    box-sizing: border-box;
    width: 700px;
    padding: 0 5px 0 5px;
    > .header {
      display: flex;
      justify-content: space-between;
      > .title {
        font-size: 16px;
        font-weight: bold;
        color: #{$greyish-brown};
        text-align: left;
      }
    }
    > .patient-name {
      margin-top: 10px;
      display: flex;
      align-items: center;
      font-size: 14px;
      > .label {
        margin-right: 15px;
      }
      > .value {
        flex: 1;
      }
    }
    > .list {
      margin-top: 15px;
      width: 100%;
      text-align: left;
      > .list-header {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 25px;
        border: 1px solid transparent;
        > .cell {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          padding: 0 5px;
          & + .cell {
            border-left: 1px solid transparent;
          }
          &.date {
            width: 130px;
          }
          &.results {
            flex: 1;
          }
          > .text {
            flex: 1;
            width: 0;
            font-size: 13px;
            font-weight: bold;
            text-align: center;
          }
        }
      }
      > .list-body {
        box-sizing: border-box;
        max-height: 401px;
        border: 1px solid $border-color;
        overflow-y: auto;
        > .row {
          box-sizing: border-box;
          display: flex;
          height: 40px;
          background-color: #{$gray_fc};
          @include hover();
          &:first-child {
            margin-top: -1px;
            border-top: 1px solid transparent;
          }
          &:nth-child(even) {
            background-color: #{$white};
          }
          & ~ .row {
            border-top: 1px solid $border-color;
          }
          > .cell {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding: 0 5px;
            & + .cell {
              border-left: 1px solid $border-color;
            }
            &.date {
              width: 130px;
            }
            &.results {
              flex: 1;
              > .text {
                text-align: left;
              }
            }
            > .text {
              flex: 1;
              width: 0;
              font-size: 13px;
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>
